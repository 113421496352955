import React from "react";
import { Media, ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { IncrementDecrementInput } from "@ryerson/frontend.form-controls";
import { useApplication } from "@ryerson/frontend.application";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { Modal } from "@ryerson/frontend.modal";
import { navigate } from "gatsby";

export type StockListHeroContent = {
	title1: string;
	title2: string;
	description: string;
	hardcopy: string;
	quantity: string;
	buttonLabel: string;
	price: string;
	downloadLabel: string;
	lookInside: string;
	imageUrl: string;
	previewInfogram: string;
};

export type StockListHeroProps = {
	content: StockListHeroContent;
};

const AddToBox = styled.div`
	display: block;
	width: 100%;
	overflow: visible;
	height: 290px;
	position: relative;
	padding: 30px 40px;
`;

const AddToBoxForm = styled.div`
	display: block;
	width: calc(100% - 580px);
	min-width: 420px;
	height: auto;
`;

const Hardcover = styled.div`
	display: block;
	position: absolute;
	bottom: -80px;
	right: 0px;
	width: calc(100% - 580px);
	text-align: center;
	height: auto;
	z-index: 1;
`;

const Inline = styled.div`
	display: inline-block;
	height: auto;
`;
const Spacer = styled.div`
	display: block;
	height: 10px;
	width: 100%;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	opacity: 0.1;
`;

const LookInside = styled.div`
	display: block;
	width: 160px;
	height: 160px;
	padding-top: 70px;
	box-sizing: border-box;
	border-radius: 160px;
	z-index: 2;
	position: absolute;
	top: -80px;
	right: -20px;
	cursor: pointer;
`;

const InfogramContainer = styled.div`
	display: block;
	width: 500px;
	margin: 0 auto;
	height: auto;
	max-width: 100%;
`;

const ModalFiller = styled.div`
	display: block;
	width: 775px;
	position: relative;
	height: auto;
	max-width: 100%;
`;

const ModalCloser = styled.div`
	width: 60px;
	height: 60px;
	position: absolute;
	top: 30px;
	right: -10px;
	border-radius: 60px;
	text-align: center;
	line-height: 68px;
	cursor: pointer;
`;

type InfogrammerType = {
	infogramId: string;
};

const Infogrammer: React.FC<InfogrammerType> = ({ infogramId }) => {
	React.useEffect(() => {
		let t: any = "InfogramEmbeds";
		let d = document.getElementsByTagName("script")[0];
		if ((window as any)[t] && (window as any)[t].initialized)
			(window as any)[t].process && (window as any)[t].process();
		else if (!document.getElementById("infogram-async")) {
			var o = document.createElement("script");
			o.async = true;
			o.id = "infogram-async";
			o.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
			if (d.parentNode) d.parentNode.insertBefore(o, d);
		}
	}, []);
	return (
		<InfogramContainer>
			<div
				className="infogram-embed"
				data-id={infogramId}
				data-type="interactive"
				data-title={""}
				css={css`
					max-width: 100%;
				`}
			></div>
		</InfogramContainer>
	);
};

const StockListHero: React.FC<StockListHeroProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const [quantityToOrder, setQuantityToOrder] = React.useState<number>(1);
	const [showPreview, setShowPreview] = React.useState<boolean>(false);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "80%",
							},
							{
								color: theme.colors.secondary.background,
								percentage: "20%",
							},
						],
					}}
					vPadding="72px"
				>
					<Typography
						variant="h1"
						css={css`
							letter-spacing: -2px;
							font-size: 75px;
							line-height: 70px;
						`}
						type="tertiary"
						color={theme.colors.primary.darkGray}
					>
						{content.title1}
					</Typography>
					<Typography
						variant="h1"
						css={css`
							letter-spacing: -2px;
							font-size: 75px;
							line-height: 70px;
							margin-bottom: 75px;
						`}
						type="tertiary"
					>
						{content.title2}
					</Typography>
					<Typography
						variant="div"
						size="md"
						css={css`
							margin-bottom: 78px;
							display: block;
							width: calc(100% - 580px);
							min-width: 420px;
							height: auto;
						`}
						type="tertiary"
					>
						{content.description}
					</Typography>
					<AddToBox
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<AddToBoxForm>
							<Typography
								variant="h4"
								css={css`
									margin-bottom: 36px;
									font-size: 20px;
								`}
								type="tertiary"
							>
								{content.hardcopy}
							</Typography>
							<Flex direction="row" justifyContent="space-between">
								<FlexItem>
									<Typography
										variant="span"
										type="tertiary"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 18px;
										`}
									>
										{content.quantity}
									</Typography>

									<Inline
										css={css`
											vertical-align: middle;
											width: 130px;
										`}
									>
										<IncrementDecrementInput
											size="lg"
											value={quantityToOrder}
											onIncrement={() => {
												setQuantityToOrder(Number(quantityToOrder) + 1);
											}}
											onDecrement={() => {
												if (quantityToOrder > 0) {
													setQuantityToOrder(quantityToOrder - 1);
												}
											}}
											onChange={(e: any) => {
												setQuantityToOrder(Number(e.target.value));
											}}
											language={language}
										/>
									</Inline>
								</FlexItem>
								<FlexItem>
									<Typography
										variant="h2"
										type="tertiary"
										css={css`
											display: inline-block;
											vertical-align: bottom;
											margin-right: 18px;
										`}
									>
										{"$" + content.price.split(".").shift()}
										<Typography
											variant="h4"
											type="tertiary"
											css={css`
												display: inline-block;
												font-size: 20px;
												vertical-align: bottom;
											`}
										>
											{"." + content.price.split(".").pop()}
										</Typography>
									</Typography>
									<Inline
										css={css`
											vertical-align: bottom;
										`}
									>
										<Button
											onClick={() => {
												if (quantityToOrder > 0) {
													navigate(
														"/stock-list/purchase/?qty=" +
															quantityToOrder
													);
												} else {
													navigate("/stock-list/purchase");
												}
											}}
											label={content.buttonLabel}
											type="primary"
											size="lg"
										/>
									</Inline>
								</FlexItem>
							</Flex>
							<Divider
								css={css`
									color: ${theme.colors.primary.white};
									margin-top: 40px;
									margin-bottom: 40px;
								`}
							/>
							<Typography
								variant="div"
								css={css`
									text-align: right;
								`}
								size="sm"
								color={theme.colors.primary.white}
							>
								<Link
									to="#download"
									underline={false}
									color={theme.colors.primary.white}
									gatsby={false}
								>
									{content.downloadLabel}
									<Inline
										css={css`
											vertical-align: middle;
											height: 24px;
											width: 24px;
											border-radius: 24px;
											background-color: ${theme.colors.primary.primaryBrand};
											text-align: center;
											line-height: 28px;
											margin-left: 5px;
										`}
									>
										<Icon
											icon="arrow-down"
											color={theme.colors.primary.white}
											css={css`
												cursor: pointer;
											`}
											size="xs"
										/>
									</Inline>
								</Link>
							</Typography>
						</AddToBoxForm>

						<Hardcover>
							<img
								src={content.imageUrl}
								css={css`
									display: inline-block;
									max-width: calc(100% - 40px);
								`}
							/>
							<LookInside
								onClick={() => {
									setShowPreview(true);
								}}
								css={css`
									background-color: ${theme.colors.primary.primaryBrand};
									cursor: pointer;
								`}
							>
								<Typography
									variant="div"
									css={css`
										line-height: 20px;
										text-align: center;
									`}
									color={theme.colors.primary.white}
									size="md"
								>
									{content.lookInside}
								</Typography>
							</LookInside>
						</Hardcover>
					</AddToBox>
					<Spacer />
				</ContentSection>
				<Modal
					closable={true}
					isOpen={showPreview}
					onClose={() => {
						setShowPreview(false);
					}}
					type="secondary"
					bodyType="secondary"
					modalStyle="default"
				>
					<ModalFiller>
						<Infogrammer infogramId={content.previewInfogram} />
						<ModalCloser
							onClick={() => {
								setShowPreview(false);
							}}
							css={css`
								background-color: ${theme.colors.primary.darkGray};
							`}
						>
							<Icon icon="close" size="sm" color={theme.colors.primary.white} />
						</ModalCloser>
					</ModalFiller>
				</Modal>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="25px">
					<Typography
						variant="h3"
						css={css`
							letter-spacing: -2px;
							max-width: 50%;
						`}
						type="tertiary"
						color={theme.colors.primary.darkGray}
					>
						{content.title1}
					</Typography>
					<Typography
						variant="h3"
						css={css`
							max-width: 50%;
							letter-spacing: -2px;
							margin-bottom: 35px;
						`}
						type="tertiary"
					>
						{content.title2}
					</Typography>

					<AddToBox
						css={css`
							background-color: ${theme.colors.primary.darkGray};
							height: auto;
							padding: 15px 21px;
						`}
					>
						<AddToBoxForm
							css={css`
								width: 100%;
								min-width: 100%;
							`}
						>
							<Typography
								variant="h5"
								css={css`
									max-width: calc(100% - 120px);
									display: block;
									margin-bottom: 0px;
								`}
								type="tertiary"
							>
								{content.hardcopy}
							</Typography>

							<Divider
								css={css`
									color: ${theme.colors.primary.white};
									margin-top: 15px;
									margin-bottom: 15px;
								`}
							/>
							<Flex
								direction="row"
								justifyContent="space-between"
								css={css`
									margin-bottom: 20px;
								`}
							>
								<FlexItem>
									<Typography
										variant="span"
										type="tertiary"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 8px;
										`}
									>
										{content.quantity}
									</Typography>

									<Inline
										css={css`
											vertical-align: middle;
											width: 120px;
										`}
									>
										<IncrementDecrementInput
											size="sm"
											value={quantityToOrder}
											onIncrement={() => {
												setQuantityToOrder(Number(quantityToOrder) + 1);
											}}
											onDecrement={() => {
												if (quantityToOrder > 0) {
													setQuantityToOrder(quantityToOrder - 1);
												}
											}}
											onChange={(e: any) => {
												setQuantityToOrder(Number(e.target.value));
											}}
											language={language}
										/>
									</Inline>
								</FlexItem>
								<FlexItem>
									<Typography
										variant="h2"
										type="tertiary"
										css={css`
											display: inline-block;
											vertical-align: bottom;
											margin-right: 18px;
											padding-top: 20px;
										`}
									>
										{"$" + content.price.split(".").shift()}
										<Typography
											variant="h4"
											type="tertiary"
											css={css`
												display: inline-block;
												font-size: 20px;
												vertical-align: bottom;
											`}
										>
											{"." + content.price.split(".").pop()}
										</Typography>
									</Typography>
								</FlexItem>
							</Flex>
							<Button
								onClick={() => {
									if (quantityToOrder > 0) {
										navigate("/stock-list/purchase/?qty=" + quantityToOrder);
									} else {
										navigate("/stock-list/purchase");
									}
								}}
								label={content.buttonLabel}
								type="primary"
								fullwidth={true}
								size="lg"
							/>
							<Typography
								variant="div"
								css={css`
									text-align: left;
									margin-top: 20px;
								`}
								size="sm"
								color={theme.colors.primary.white}
							>
								<Link
									to="#download"
									underline={false}
									color={theme.colors.primary.white}
									gatsby={false}
								>
									<Inline
										css={css`
											vertical-align: middle;
											height: 24px;
											width: 24px;
											border-radius: 24px;
											background-color: ${theme.colors.primary.primaryBrand};
											text-align: center;
											line-height: 28px;
											margin-right: 5px;
										`}
									>
										<Icon
											icon="arrow-down"
											color={theme.colors.primary.white}
											css={css`
												cursor: pointer;
											`}
											size="xs"
										/>
									</Inline>
									<Inline
										css={css`
											vertical-align: middle;
											width: calc(100% - 30px);
										`}
									>
										{content.downloadLabel}
									</Inline>
								</Link>
							</Typography>
						</AddToBoxForm>
						<Hardcover
							css={css`
								bottom: calc(100% - 60px);
								width: calc(100% - 158px);
								max-width: 150px;
							`}
						>
							<img
								src={content.imageUrl}
								css={css`
									display: inline-block;
									max-width: calc(100% - 20px);
									max-height: 140px;
								`}
							/>
							<LookInside
								onClick={() => {
									setShowPreview(true);
								}}
								css={css`
									background-color: ${theme.colors.primary.primaryBrand};
									cursor: pointer;
									width: 80px;
									height: 80px;
									padding-top: 24px;
									border-radius: 80px;
									padding-left: 15px;
									padding-right: 15px;
									top: -20px;
									right: 0px;
								`}
							>
								<Typography
									variant="div"
									css={css`
										line-height: 16px !important;
										text-align: center;
									`}
									color={theme.colors.primary.white}
									size="md"
								>
									{content.lookInside}
								</Typography>
							</LookInside>
						</Hardcover>
					</AddToBox>
					<Typography
						variant="div"
						size="md"
						css={css`
							margin-top: 35px;
						`}
						type="tertiary"
					>
						{content.description}
					</Typography>
					<Spacer
						css={css`
							height: 50px;
						`}
					/>
				</ContentSection>
				<Modal
					closable={true}
					isOpen={showPreview}
					onClose={() => {
						setShowPreview(false);
					}}
					type="secondary"
					bodyType="secondary"
					modalStyle="default"
				>
					<ModalFiller
						css={css`
							position: relative;
						`}
					>
						<Infogrammer infogramId={content.previewInfogram} />
						<ModalCloser
							onClick={() => {
								setShowPreview(false);
							}}
							css={css`
								background-color: ${theme.colors.primary.darkGray};
								@media (max-width: ${theme.breakpoints.lg}) {
									right: -30px;
									top: 10px;
									line-height: 32px;
									width: 30px;
									height: 30px;
								}
							`}
						>
							<Icon icon="close" size="xs" color={theme.colors.primary.white} />
						</ModalCloser>
					</ModalFiller>
				</Modal>
			</Media>
		</>
	);
};

export default StockListHero;
